import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = exports.FeedbackModal = exports.close = exports.open = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
/**
 * Action for the Feedback Modal group
 * @public
 */
var Action;
(function (Action) {
  Action["OPEN"] = "APP::FEEDBACK_MODAL::OPEN";
  Action["CLOSE"] = "APP::FEEDBACK_MODAL::CLOSE";
})(Action = exports.Action || (exports.Action = {}));
function open(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.FeedbackModal,
    payload: payload,
    type: Action.OPEN
  });
}
exports.open = open;
function close(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.FeedbackModal,
    payload: payload,
    type: Action.CLOSE
  });
}
exports.close = close;
/**
 * FeedbackModal action set
 */
var FeedbackModal = /** @class */function (_super) {
  __extends(FeedbackModal, _super);
  /**
   * Returns a new instance of a FeedbackModal action set
   * @param app the client application
   */
  function FeedbackModal(app, options) {
    var _this = _super.call(this, app, types_1.Group.FeedbackModal, types_1.Group.FeedbackModal) || this;
    _this.options = options;
    _this.set(options);
    return _this;
  }
  Object.defineProperty(FeedbackModal.prototype, "payload", {
    /**
     * Returns the action set payload
     */
    get: function () {
      return __assign({
        id: this.id
      }, this.options);
    },
    enumerable: false,
    configurable: true
  });
  FeedbackModal.prototype.set = function (options) {
    this.options = helper_1.getMergedProps(this.options, options);
    return this;
  };
  /**
   * Dispatches a given action with the action set payload
   * @param action the action enum
   * @returns the action set instance
   */
  FeedbackModal.prototype.dispatch = function (action) {
    switch (action) {
      case Action.OPEN:
        {
          var openAction = open(this.payload);
          this.app.dispatch(openAction);
          break;
        }
      case Action.CLOSE:
        {
          var closeAction = close(this.payload);
          this.app.dispatch(closeAction);
          break;
        }
    }
    return this;
  };
  return FeedbackModal;
}(ActionSet_1.ActionSet);
exports.FeedbackModal = FeedbackModal;
/**
 * Returns a new instance of a FeedbackModal action set
 * @param app the client application
 */
function create(app, options) {
  return new FeedbackModal(app, options);
}
exports.create = create;
export default exports;
export const __esModule = exports.__esModule;
const _create = exports.create,
  _FeedbackModal = exports.FeedbackModal,
  _close = exports.close,
  _open = exports.open,
  _Action = exports.Action;
export { _create as create, _FeedbackModal as FeedbackModal, _close as close, _open as open, _Action as Action };